import * as R from 'core/helpers/remeda'
import * as S from 'core/helpers/string'
import {
  InteractionAgentOutcome,
  InteractionDirection,
  InteractionStatus,
  InteractionStatusDetails,
  InteractionTheme,
} from 'core/types/types'

export const interactionOutcomeToStatus = {
  voiceSpokeWithFirstParty: 'succeeded',
  voiceSpokeWithThirdParty: 'succeeded',
  voiceUnexpectedDisconnect: 'attempted',
  voiceLeftVoicemail: 'attempted',
  voiceLineBusyNoRing: 'attempted',
  voicePickedHungUp: 'attempted',
  voiceRangNoPickup: 'attempted',
  voiceReachedVoicemailNoMessageLeft: 'attempted',
  voiceFailedToInitiate: 'failed',
  voiceNumberDisconnected: 'failed',
} as const satisfies { [_ in NonNullable<InteractionAgentOutcome>]: InteractionStatus }

export const interactionOutcomeToStatusDetail = {
  voiceSpokeWithFirstParty: 'voiceConnected',
  voiceSpokeWithThirdParty: 'voiceConnected',
  voiceUnexpectedDisconnect: 'voiceConnected',
  voiceLeftVoicemail: 'voiceConnected',
  voiceLineBusyNoRing: 'voiceConnected',
  voicePickedHungUp: 'voiceConnected',
  voiceRangNoPickup: 'voiceConnected',
  voiceReachedVoicemailNoMessageLeft: 'voiceConnected',
  voiceFailedToInitiate: 'voiceFailedToInitiate',
  voiceNumberDisconnected: 'voiceNumberDisconnected',
} as const satisfies { [_ in NonNullable<InteractionAgentOutcome>]: InteractionStatusDetails }

export const interactionOutboundOutcomes = [
  'voiceSpokeWithFirstParty',
  'voiceSpokeWithThirdParty',
  'voiceUnexpectedDisconnect',
  'voiceLeftVoicemail',
  'voiceLineBusyNoRing',
  'voicePickedHungUp',
  'voiceRangNoPickup',
  'voiceReachedVoicemailNoMessageLeft',
  'voiceFailedToInitiate',
  'voiceNumberDisconnected',
] as const satisfies Array<InteractionAgentOutcome>

export const interactionInboundOutcomes = [
  'voiceSpokeWithFirstParty',
  'voiceSpokeWithThirdParty',
  'voiceUnexpectedDisconnect',
] as const satisfies Array<InteractionAgentOutcome>

export const getInteractionOutcomeOptions = (direction: InteractionDirection | undefined) =>
  R.map(direction === 'inbound' ? interactionInboundOutcomes : interactionOutboundOutcomes, (outcome) => ({
    label: `${S.capitalize(interactionOutcomeToStatus[outcome])} - ${interactionStatusDetailLabels[outcome]}`,
    value: outcome,
  }))

export const interactionThemeLabels = {
  inbCollections: 'Collections',
  inbEducation: 'Education',
  inbFraud: 'Fraud',
  inbGeneralInquiry: 'General Inquiry',
  inbMissingFeature: 'Missing feature',
  inbOther: 'Other',
  inbPayments: 'Payments',
  inbRequest: 'Request',
  inbServicing: 'Servicing',
  inbUnknown: 'Unknown',
  opsAccountCredentials: 'Account credentials',
  opsCollContactEmployerNotice: 'Collections: Intention to contact employer notice',
  opsCollDebt: 'Collections: General',
  opsCollLocateBorrower: 'Collections: Locate borrower for collections',
  opsCollVerifyEmployment: 'Collections: Verify borrower employment',
  opsServicing: 'Servicing: General',
  opsServicingDebtValidation: 'Servicing: Debt Validation',
  opsServicingNegativeCreditReportNotice: 'Negative credit report notice',
  opsServicingTimeBarredNotice: 'Servicing: Time-barred notice',
} as const satisfies { [_ in NonNullable<InteractionTheme>]?: string }

export const interactionThemeOptions = R.pipe(
  interactionThemeLabels,
  R.toPairs.strict,
  R.map(([value, label]) => ({ label, value })),
)

export const interactionOutboundThemes = [
  'opsCollContactEmployerNotice',
  'opsCollDebt',
  'opsCollLocateBorrower',
  'opsCollVerifyEmployment',
  'opsServicing',
  'opsServicingDebtValidation',
] as const satisfies Array<NonNullable<InteractionTheme>>

export const interactionInboundThemes = [
  'inbCollections',
  'inbEducation',
  'inbFraud',
  'inbGeneralInquiry',
  'inbMissingFeature',
  'inbOther',
  'inbPayments',
  'inbRequest',
  'inbServicing',
  'inbUnknown',
] as const satisfies Array<NonNullable<InteractionTheme>>

export const interactionOutboundThemeOptions = interactionOutboundThemes.map((value) => ({
  label: interactionThemeLabels[value] ?? value,
  value,
}))

export const interactionInboundThemeOptions = interactionInboundThemes.map((value) => ({
  label: interactionThemeLabels[value] ?? value,
  value,
}))

export const interactionInboundBorrowerThemeOptions = R.reject(
  interactionInboundThemeOptions,
  (option) => option.value === 'inbUnknown',
)

export const getInteractionThemeOptions = (direction: InteractionDirection | undefined) =>
  direction === 'inbound' ? interactionInboundThemeOptions : interactionOutboundThemeOptions

export const missedCallStatusDetails = [
  'voiceDisconnectedWhileQueuedOnHold',
  'voiceLenderDisabledInboundCalls',
  'voiceReceivedNoAgentsAvailable',
  'voiceReceivedOutsideOpenHours',
] as const satisfies Array<NonNullable<InteractionStatusDetails>>

const interactionStatusDetailLabels = {
  blockedByComplianceGuard: 'Blocked by Compliance Guard',
  chatBorrowerInactivity: 'Chat Borrower Inactivity',
  chatPosted: 'Chat Posted',
  chatRead: 'Chat Read',
  chatReceivedOutsideOpenHours: 'Chat Received Outside Open Hours',
  emailBlocked: 'Email Blocked',
  emailBounced: 'Email Bounced',
  emailClick: 'Email Click',
  emailDelivered: 'Email Delivered',
  emailDropped: 'Email Dropped',
  emailFailedToSend: 'Email Failed to Send',
  emailOpened: 'Email Opened',
  emailReportedAsSpam: 'Email Reported as Spam',
  emailSent: 'Email Sent',
  invalidContact: 'Invalid Contact',
  letterCreated: 'Letter Created',
  letterDeleted: 'Letter Deleted',
  letterDelivered: 'Letter Delivered',
  letterFailedToCreate: 'Letter Failed To Create',
  letterInLocalArea: 'Letter In Local Area',
  letterInTransit: 'Letter In Transit',
  letterProcessedForDelivery: 'Letter Processed For Delivery',
  letterRenderedPdf: 'Letter Rendered Pdf',
  letterRenderedThumbnails: 'Letter Rendered Thumbnails',
  letterRerouted: 'Letter Rerouted',
  letterReturnedToSender: 'Letter Returned To Sender',
  textA2PCampaignUnregistered: 'Text A2P Campaign Unregistered',
  textAccountSuspended: 'Text Account Suspended',
  textCarrierViolation: 'Text Carrier Violation',
  textConvoClosedByAgent: 'Text Convo Closed By Agent',
  textConvoClosedStale: 'Text Convo Closed Stale',
  textConvoTimeoutAutoclose: 'Text Convo Timeout Autoclose',
  textConvoUnknownNumberRejected: 'Text Convo Unknown Number Rejected',
  textDeviceUnreachable: 'Text Device Unreachable',
  textLandlineOrUnreachableCarrier: 'Text Landline Or Unreachable Carrier',
  textLenderDisabledInboundTexts: 'Text Lender Disabled Inbound Texts',
  textMessageBlocked: 'Text Message Blocked',
  textMessagePriceExceedsMax: 'Text Message Price Exceeds Max',
  textMissingSegment: 'Text Missing Segment',
  textNumberDisconnected: 'Text Number Disconnected',
  textQueueOverflow: 'Text Queue Overflow',
  textRegionInvalid: 'Text Region Invalid',
  textUnknownError: 'Text Unknown Error',
  textUnsubscribedRecipient: 'Text Unsubscribed Recipient',
  voiceConnected: 'Voice Connected',
  voiceDisconnectedWhileQueuedOnHold: 'Voice Disconnected While Queued On Hold',
  voiceFailedToInitiate: 'Voice Failed To Initiate',
  voiceLeftVoicemail: 'Voice Left Voicemail',
  voiceLeftVoicemailFirstParty: 'Voice Left Voicemail First Party',
  voiceLeftVoicemailThirdParty: 'Voice Left Voicemail Third Party',
  voiceLenderDisabledInboundCalls: 'Voice Lender Disabled Inbound Calls',
  voiceLineBusyNoRing: 'Voice Line Busy No Ring',
  voiceNumberDisconnected: 'Voice Number Disconnected',
  voiceNumberInvalid: 'Voice Number Invalid',
  voicePickedHungUp: 'Voice Picked Hung Up',
  voiceRangNoPickup: 'Voice Rang No Pickup',
  voiceReachedVoicemailNoMessageLeft: 'Voice Reached Voicemail No Message Left',
  voiceReceivedNoAgentsAvailable: 'Voice Received No Agents Available',
  voiceReceivedOutsideOpenHours: 'Voice Received Outside Open Hours',
  voiceSpokeWithFirstParty: 'Voice Spoke With First Party',
  voiceSpokeWithThirdParty: 'Voice Spoke With Third Party',
  voiceUnexpectedDisconnect: 'Voice Unexpected Disconnect',
} as const satisfies { [_ in NonNullable<InteractionStatusDetails>]?: string }

export const interactionStatusDetailsOptions = R.pipe(
  interactionStatusDetailLabels,
  R.toPairs.strict,
  R.map(([value, label]) => ({ label, value })),
)

export const interactionStatuses = {
  attempted: 'Attempted',
  succeeded: 'Succeeded',
  failed: 'Failed',
  scheduled: 'Scheduled',
  canceled: 'Canceled',
  inProgress: 'In Progress',
} as const satisfies { [_ in NonNullable<InteractionStatus>]?: string }

export const interactionStatusesOptions = R.pipe(
  interactionStatuses,
  R.keys,
  R.map((key) => ({ label: interactionStatuses[key], value: key })),
)
