const loanAppLayout = {
  maxWidthMobile: '1023',
  minWidthDesktop: '1024',
}

const crmAppLayout = {
  maxWidthMain: '1920px',
  minWidthMain: '1376px',
  maxWidthBorrowerContent: '1448px',
  minWidthBorrowercontent: '1280px',
}

export { crmAppLayout, loanAppLayout }
