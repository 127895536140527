import qs from 'qs'

import { redirectToLogin } from 'app/loginRedirect/loginRedirectHelpers'

import { APIendpoint, PEACH_UTM, getStoredToken, setSessionExpiredIfRecent } from 'core/actions/helpers/tokenHelpers'
import { assert } from 'core/helpers/assert'
import * as R from 'core/helpers/remeda'
import { retrieveSession } from 'core/hooks/useStoredState/storage'

import rawApiCall, { FetchOptions, parseErrorResponse } from './rawApiCall'

const unauthorized = () => {
  setSessionExpiredIfRecent()
  redirectToLogin()
}

const peachApiCall = ({ url, headers, ...restOptions }: FetchOptions) => {
  try {
    return rawApiCall({
      url: url.startsWith('http') ? url : APIendpoint + url,
      headers: {
        'Peach-Version': import.meta.env.VITE_PEACH_API_VERSION,
        Authorization: `Bearer ${getStoredToken()}`,
        ...(retrieveSession(PEACH_UTM) ?? {}),
        ...headers,
      },
      ...restOptions,
    })
  } catch (error) {
    if (R.isObject(error) && 'status' in error && error.status === 401) {
      unauthorized()
    }
    throw error
  }
}

const peachApi = {
  fetch: peachApiCall,
  get: (options: FetchOptions) => peachApiCall({ ...options, method: 'GET' }),
  post: (options: FetchOptions) => peachApiCall({ ...options, method: 'POST' }),
  put: (options: FetchOptions) => peachApiCall({ ...options, method: 'PUT' }),
  patch: (options: FetchOptions) => peachApiCall({ ...options, method: 'PATCH' }),
  delete: (options: FetchOptions) => peachApiCall({ ...options, method: 'DELETE' }),

  /** @deprecated */
  getAll: async (options: FetchOptions, result: any = {}, depth = 0) => {
    const resp = await peachApiCall({ ...options, method: 'GET' })

    assert(R.isArray(resp?.data), 'Must return a paginated list.')

    const newResult = {
      ...result,
      ...resp,
      count: (result?.count ?? 0) + resp.count,
      data: (result?.data ?? []).concat(resp.data),
    }

    if (resp.nextUrl) {
      assert(depth < 10, 'Must be less than 10 pages')

      return peachApi.getAll({ ...options, url: resp.nextUrl }, newResult, depth + 1)
    }

    return newResult
  },

  /**
   * Use this when needing to directly download a file that requires authorization.
   * Create a temporary a-tag with href set to a fancy url that actually has all
   * the data embedded into it, then programmatically click it. This triggers the
   * browser's file download behavior to kick in. The "download" attribute is set
   * so that the browser knows what the default filename is.
   */
  download: async ({ url, query, ...restOptions }: Omit<FetchOptions, 'body'>) => {
    const response = await fetch(
      APIendpoint + url + qs.stringify(query, { addQueryPrefix: true, arrayFormat: 'comma' }),
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getStoredToken()}`,
        },
        ...restOptions,
      },
    )

    if (response.ok) {
      const a = document.createElement('a')
      a.setAttribute('href', `data:application/octet-stream,${await response.text()}`)
      a.setAttribute(
        'download',
        R.find((response.headers.get('Content-Disposition') || '').split(';'), (item) =>
          item.trim().startsWith('filename='),
        )
          ?.trim()
          .split('=')[1] ??
          R.last(url.split('/')) ??
          '',
      )
      a.click()
      a.remove()
    } else {
      if (response.status === 401) {
        unauthorized()
      } else {
        const error = await parseErrorResponse(response)
        throw error
      }
    }
  },
}

export default peachApi
