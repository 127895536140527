import { FC } from 'react'

import { Scope } from '@sentry/react'
import { LDProvider } from 'launchdarkly-react-client-sdk'

import App from 'app/App'
import DraftContextProvider from 'contexts/DraftContext'
import FileUploadContextProvider from 'contexts/FileUploadContext/provider'

import { PeachOverrides, Spinner } from 'core/components'
import { ErrorMessage } from 'core/components/lib/ErrorBoundary/defaultErrorBoundaries'
import ErrorBoundary from 'core/components/lib/ErrorBoundary/ErrorBoundary'
import { addToast, fetchBootstrap, useBootstrapStore } from 'core/stores'
import ToastViewport from 'core/stores/toasts/ToastViewport'
import ErrorLogging from 'core/system/ErrorLogging'
import { useErrorLoggingScope } from 'core/system/useErrorLoggingScope'

import CommonProviders from './CommonProvider'
import useActivityRenewal from './session/useActivityRenewal'

void fetchBootstrap()

window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
  ErrorLogging.withScope((scope: Scope) => {
    scope.setTag('ident', 'UnhandledExceptions')
    ErrorLogging.captureException(event.reason)
  })
  addToast(<ErrorMessage error={event.reason} />, 'error')
})

const Root: FC = () => {
  const bootstrap = useBootstrapStore()
  const { appDomainType, userType, company } = bootstrap ?? {}

  useErrorLoggingScope({ company, appDomainType, userType })

  useActivityRenewal()

  return (
    <ErrorBoundary ident='AppRoot' isAppRoot>
      <ToastViewport />
      {bootstrap === null ?
        <Spinner height='100vh' />
      : <LDProvider
          clientSideID={import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID}
          context={{ key: company?.id ?? 'unknown' }}
        >
          <CommonProviders>
            <DraftContextProvider>
              <FileUploadContextProvider>
                <App />
                <PeachOverrides />
              </FileUploadContextProvider>
            </DraftContextProvider>
          </CommonProviders>
        </LDProvider>
      }
    </ErrorBoundary>
  )
}

export default Root
