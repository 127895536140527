import { useState } from 'react'

import { getDay } from 'date-fns'
import _ from 'lodash'
import { styled } from 'styled-components'

import Icon from 'core/components/Icon'
import Button from 'core/components/lib/Button'
import Spinner from 'core/components/lib/Spinner'
import { R, getNow } from 'core/helpers'
import { variables } from 'core/styles'
import { useGetFrontendConfig } from 'core/types'
import { SingleDayHours, Hours } from 'core/types/lenderConfig'

import formatDayOfWeekHours from './helpers/formatDayOfWeekHours'

type SingleDayHoursProps = {
  isBold?: boolean
}

const HoursSection = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${variables.colorBlack70};
`
const SingleDay = styled.div<SingleDayHoursProps>`
  display: flex;
  margin-bottom: 8px;
  font-weight: ${(p) => (p.isBold ? '500' : 'normal')};
`
const Day = styled.div`
  width: 120px;
`

const sortedDaysOfWeek = (dayOfWeek: number, hours?: Array<SingleDayHours>) => {
  // Shouldn't be falsy but placed here just in case,
  // and also to prevent TS error
  if (!hours || _.isEmpty(hours)) return null

  // Early return if dayOfWeek is Monday
  if (dayOfWeek === 0) return hours

  const arrayStart = hours.slice(dayOfWeek)
  const arrayEnd = hours.slice(0, dayOfWeek)
  return [...arrayStart, ...arrayEnd]
}

const DayOfWeekHoursList = ({ expanded = true }: { expanded?: boolean }) => {
  const [showHours, setShowHours] = useState(expanded)

  const { data: config, isLoading } = useGetFrontendConfig({})

  const { holidays, normal } = ((config?.openHours as any)?.businessHours as Hours) ?? {}
  const holidaysByDate = R.indexBy(holidays ?? [], R.prop('date'))

  const day = getDay(getNow())
  const sortedHours = sortedDaysOfWeek(day === 0 ? 6 : day - 1, normal)

  return (
    isLoading ? <Spinner />
    : !sortedHours ? null
    : <HoursSection>
        <div>
          {R.map.indexed(sortedHours, (singleDay, index) => {
            const { dayString, hoursString } = formatDayOfWeekHours(singleDay, holidaysByDate)

            return !showHours && index > 0 ?
                null
              : <SingleDay data-testid={`hours-for-${singleDay.day}`} isBold={index === 0} key={singleDay.day}>
                  <Day>{dayString}</Day>
                  <div>{hoursString}</div>
                </SingleDay>
          })}
        </div>
        {showHours ?
          <Button data-testid='button-arrow-up' fontSize='24px' onClick={() => setShowHours(false)}>
            <Icon name='arrow_drop_up' />
          </Button>
        : <Button data-testid='button-arrow-down' fontSize='24px' onClick={() => setShowHours(true)}>
            <Icon name='arrow_drop_down' />
          </Button>
        }
      </HoursSection>
  )
}

export { SingleDay, sortedDaysOfWeek }
export default DayOfWeekHoursList
