import { FC } from 'react'

import { styled } from 'styled-components'

import parseError from 'core/helpers/parseError'
import * as R from 'core/helpers/remeda'
import { useCompanyId } from 'core/stores'
import { variables } from 'core/styles'

export const hasStatus = (e: unknown): e is { status: number } =>
  !!e && typeof e === 'object' && 'status' in e && typeof e.status === 'number'

export type FallbackProps = {
  className?: string
  error?: unknown
}

type ErrorMessageProps = {
  error: unknown
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ error }) => {
  const companyId = useCompanyId()

  const operation = R.isObject(error) && R.isString(error.operation) ? error.operation : undefined
  const msg =
    hasStatus(error) && (error.status > 499 || error.status < 400) ? undefined : parseError(error, '', companyId)

  return (
    <>
      An unexpected error has occurred
      {operation && (
        <>
          {' '}
          with: <i>{operation}</i>
        </>
      )}
      . If the problem persists contact support describing what you were doing when this error occurred.
      {msg && (
        <>
          {' '}
          Additional details: <i>{msg}</i>
        </>
      )}
    </>
  )
}

export const ErrorBox = styled.div<{ $type?: 'error' | 'warning' }>`
  position: relative;
  background-color: ${(p) => (p.$type === 'warning' ? variables.colorOrangeLighten : 'white')};
  padding: 8px 16px;
  color: ${variables.colorBlack100};

  & + & {
    margin-top: 12px;
  }
`
export const ErrorTitle = styled.h1`
  font-size: 18px;
  font-weight: 500;
`
export const BoxContent = styled.p`
  margin-top: 0;
  font-size: 15px;
`
