/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import makeQueryHook from 'core/types/makeQueryHook'
import * as operations from 'core/types/operations'

export const useBootstrap = makeQueryHook(operations.bootstrap)

export const useGetFrontendConfig = makeQueryHook(operations.getFrontendConfig)
