import { isLoggedIn } from 'core/actions/helpers/tokenHelpers'
import useGetZendeskToken from 'core/data/authQueries/useGetZendeskToken'
import { R } from 'core/helpers'
import { useBootstrapStore, useCompanyId, useUserType } from 'core/stores'
import { useGetFrontendConfig } from 'core/types'

export type Option = {
  label: string
  action?: string
  href?: string
  icon?: string
  disabled?: boolean
  agent?: boolean
}

const useAccountOptions = ({ isMobile }: { isMobile?: boolean } = {}) => {
  const { helpUrl, helpEnabled } = useBootstrapStore((data) => ({
    helpUrl: data?.company?.links?.helpUrl,
    helpEnabled: data?.company?.ui?.help !== 'hidden',
  }))

  const userType = useUserType()
  const companyId = useCompanyId()

  const enabled = isLoggedIn()

  const { data: config, isLoading } = useGetFrontendConfig({ options: { enabled } })

  const [zendeskToken, zendeskTokenQuery] = useGetZendeskToken({ companyId, options: { enabled } })

  return R.compact<Option>(
    isLoading || zendeskTokenQuery.isLoading ? []
    : !enabled ?
      [
        {
          label: 'Login',
          action: '/login',
        },
        helpEnabled && {
          label: 'Help & FAQ',
          href: helpUrl,
          icon: 'help',
          disabled: !helpUrl,
        },
      ]
    : [
        isMobile && {
          label: 'Loans',
          action: '/loans',
          icon: 'home',
        },
        config?.ui?.accountSettings !== 'hidden' && {
          label: 'Settings',
          action: '/account-settings',
          icon: 'settings',
          disabled: false,
        },
        helpEnabled && {
          label: 'Help & FAQ',
          href: helpUrl,
          icon: 'help',
          disabled: !helpUrl,
        },
        config?.ui?.contactUs !== 'hidden' && {
          label: 'Contact us',
          action: '/contact-us',
          icon: 'alternate_email',
          disabled: false,
        },
        config?.ui?.signOut !== 'hidden' && {
          label: 'Sign out',
          action: '/sign-out',
          icon: 'exit_to_app',
          disabled: userType === 'agent',
        },
        userType === 'agent' && {
          label: 'Manage borrower information',
          action: '/borrower-information',
          icon: 'account_circle',
          agent: true,
        },
        userType === 'agent' && {
          label: 'Manage identifying information',
          action: '/identifying-information',
          icon: 'portrait',
          agent: true,
        },
        userType === 'agent' && {
          label: 'Manage consents',
          action: '/manage-consents',
          icon: 'dns',
          agent: true,
        },
        userType === 'agent' &&
          !zendeskTokenQuery?.isError &&
          zendeskToken && {
            label: 'Peach support',
            href: `https://peachfinance.zendesk.com/access/jwt?jwt=${zendeskToken}`,
            icon: 'help',
            agent: true,
            isExternal: true,
            externalAction: zendeskTokenQuery?.refetch,
          },
      ],
  )
}

export default useAccountOptions
