import { DependencyList, useEffect } from 'react'

import { UseFormReturn, FieldValues, WatchObserver } from 'react-hook-form'

const useWatchForm = <TFieldValues extends FieldValues>(
  form: UseFormReturn<TFieldValues>,
  callback: WatchObserver<TFieldValues>,
  deps: DependencyList = [],
) => {
  useEffect(() => {
    const sub = form.watch(callback)

    return sub.unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, ...deps])
}

export default useWatchForm
