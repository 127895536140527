/* eslint-disable import-x/export */
import {
  addCaseAssociation as addCaseAssociationBase,
  createInteractionNote as createInteractionNoteBase,
  loanRefundsCancel as loanRefundsCancelBase,
  removeCaseAssociation as removeCaseAssociationBase,
  updateInteractionNote as updateInteractionNoteBase,
  listUnterminatedTasks as listUnterminatedTasksBase,
  internalEmployeesGet as internalEmployeesGetBase,
  employeesGet as employeesGetBase,
  internalEmployeeGetById as internalEmployeeGetByIdBase,
  employeeGetById as employeeGetByIdBase,
  listCaseType as listCaseTypeBase,
  getEvents as getEventsBase,
} from './generated/operations'
import type { OperationData } from './makeMutationHook'
import {
  AddCaseAssociation,
  CreateInteractionNote,
  InternalEmployeeGetById,
  InternalEmployeesGet,
  LoanRefundsCancel,
  RemoveCaseAssociation,
  UpdateInteractionNote,
  ListUnterminatedTasks,
  EmployeesGet,
  EmployeeGetById,
  ListCaseType,
  GetEvents,
} from './types'

export * from './generated/operations'

export const addCaseAssociation = addCaseAssociationBase as OperationData<AddCaseAssociation>

export const createInteractionNote = createInteractionNoteBase as OperationData<CreateInteractionNote>

export const employeeGetById = employeeGetByIdBase as OperationData<EmployeeGetById>

export const employeesGet = employeesGetBase as OperationData<EmployeesGet>

export const internalEmployeeGetById = internalEmployeeGetByIdBase as OperationData<InternalEmployeeGetById>

export const internalEmployeesGet = internalEmployeesGetBase as OperationData<InternalEmployeesGet>

export const listCaseType = listCaseTypeBase as OperationData<ListCaseType>

export const listUnterminatedTasks = listUnterminatedTasksBase as OperationData<ListUnterminatedTasks>

export const loanRefundsCancel = loanRefundsCancelBase as OperationData<LoanRefundsCancel>

export const removeCaseAssociation = removeCaseAssociationBase as OperationData<RemoveCaseAssociation>

export const updateInteractionNote = updateInteractionNoteBase as OperationData<UpdateInteractionNote>

// TODO sc-41270
export const getEvents = getEventsBase as OperationData<GetEvents>
