/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import type { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const supercaseList: OperationData<Types.SupercaseList> = {
  method: 'get',
  name: 'supercaseList',
  summary: 'List supercases',
  path: '/supercases',
  queryKey: () => ['supercases'],
}

export const supercaseCreate: OperationData<Types.SupercaseCreate> = {
  method: 'post',
  name: 'supercaseCreate',
  summary: 'Create supercase',
  path: '/supercases',
  queryKey: () => ['supercases'],
}

export const supercaseMembersTemplateCsv: OperationData<Types.SupercaseMembersTemplateCsv> = {
  method: 'get',
  name: 'supercaseMembersTemplateCsv',
  summary: 'Get supercase members.csv template',
  path: '/supercases/members-template.csv',
  queryKey: () => ['supercases', 'members-template.csv'],
}

export const supercaseGet: OperationData<Types.SupercaseGet> = {
  method: 'get',
  name: 'supercaseGet',
  summary: 'Get supercase by ID',
  path: '/supercases/{supercaseId}',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId],
}

export const supercaseUpdate: OperationData<Types.SupercaseUpdate> = {
  method: 'put',
  name: 'supercaseUpdate',
  summary: 'Update supercase',
  path: '/supercases/{supercaseId}',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId],
}

export const populationList: OperationData<Types.PopulationList> = {
  method: 'get',
  name: 'populationList',
  summary: 'List populations',
  path: '/supercases/{supercaseId}/populations',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'populations'],
}

export const committedMembersListCsv: OperationData<Types.CommittedMembersListCsv> = {
  method: 'get',
  name: 'committedMembersListCsv',
  summary: 'Get committed membership as CSV',
  path: '/supercases/{supercaseId}/populations/members.csv',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'populations', 'members.csv'],
}

export const draftPopulationGet: OperationData<Types.DraftPopulationGet> = {
  method: 'get',
  name: 'draftPopulationGet',
  summary: 'Get draft population',
  path: '/supercases/{supercaseId}/populations/draft',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'populations', 'draft'],
}

export const draftPopulationMembersList: OperationData<Types.DraftPopulationMembersList> = {
  method: 'get',
  name: 'draftPopulationMembersList',
  summary: 'Get the draft population members',
  path: '/supercases/{supercaseId}/populations/draft/members',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'populations', 'draft', 'members'],
}

export const draftPopulationMembersSet: OperationData<Types.DraftPopulationMembersSet> = {
  method: 'put',
  name: 'draftPopulationMembersSet',
  summary: 'Set the draft population membership',
  path: '/supercases/{supercaseId}/populations/draft/members',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'populations', 'draft', 'members'],
}

export const draftPopulationMembersAdd: OperationData<Types.DraftPopulationMembersAdd> = {
  method: 'post',
  name: 'draftPopulationMembersAdd',
  summary: 'Add to the draft population membership',
  path: '/supercases/{supercaseId}/populations/draft/members',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'populations', 'draft', 'members'],
}

export const draftPopulationMembersListCsv: OperationData<Types.DraftPopulationMembersListCsv> = {
  method: 'get',
  name: 'draftPopulationMembersListCsv',
  summary: 'Get membership as CSV',
  path: '/supercases/{supercaseId}/populations/draft/members.csv',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'populations', 'draft', 'members.csv'],
}

export const draftPopulationMembersSetCsv: OperationData<Types.DraftPopulationMembersSetCsv> = {
  method: 'put',
  name: 'draftPopulationMembersSetCsv',
  summary: 'Set membership with CSV',
  path: '/supercases/{supercaseId}/populations/draft/members.csv',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'populations', 'draft', 'members.csv'],
}

export const draftPopulationMembersAddCsv: OperationData<Types.DraftPopulationMembersAddCsv> = {
  method: 'post',
  name: 'draftPopulationMembersAddCsv',
  summary: 'Add to membership with CSV',
  path: '/supercases/{supercaseId}/populations/draft/members.csv',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'populations', 'draft', 'members.csv'],
}

export const draftPopulationCommit: OperationData<Types.DraftPopulationCommit> = {
  method: 'post',
  name: 'draftPopulationCommit',
  summary: 'Commit draft population',
  path: '/supercases/{supercaseId}/populations/draft/commit',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'populations', 'draft', 'commit'],
}

export const populationGet: OperationData<Types.PopulationGet> = {
  method: 'get',
  name: 'populationGet',
  summary: 'Get population by ID',
  path: '/supercases/{supercaseId}/populations/{populationId}',
  queryKey: ({ supercaseId, populationId }) => ['supercases', supercaseId, 'populations', populationId],
}

export const populationUpdate: OperationData<Types.PopulationUpdate> = {
  method: 'put',
  name: 'populationUpdate',
  summary: 'Update population',
  path: '/supercases/{supercaseId}/populations/{populationId}',
  queryKey: ({ supercaseId, populationId }) => ['supercases', supercaseId, 'populations', populationId],
}

export const populationMembersList: OperationData<Types.PopulationMembersList> = {
  method: 'get',
  name: 'populationMembersList',
  summary: 'Get population membership',
  path: '/supercases/{supercaseId}/populations/{populationId}/members',
  queryKey: ({ supercaseId, populationId }) => ['supercases', supercaseId, 'populations', populationId, 'members'],
}

export const populationMembersListCsv: OperationData<Types.PopulationMembersListCsv> = {
  method: 'get',
  name: 'populationMembersListCsv',
  summary: 'Get membership as CSV',
  path: '/supercases/{supercaseId}/populations/{populationId}/members.csv',
  queryKey: ({ supercaseId, populationId }) => ['supercases', supercaseId, 'populations', populationId, 'members.csv'],
}

export const internalSupercaseList: OperationData<Types.InternalSupercaseList> = {
  method: 'get',
  name: 'internalSupercaseList',
  summary: 'List company supercases',
  path: '/companies/{companyId}/supercases',
  queryKey: ({ companyId }) => ['companies', companyId, 'supercases'],
}

export const internalSupercaseCreate: OperationData<Types.InternalSupercaseCreate> = {
  method: 'post',
  name: 'internalSupercaseCreate',
  summary: 'Create supercase',
  path: '/companies/{companyId}/supercases',
  queryKey: ({ companyId }) => ['companies', companyId, 'supercases'],
}

export const privateNoteList: OperationData<Types.PrivateNoteList> = {
  method: 'get',
  name: 'privateNoteList',
  summary: 'Get private notes',
  path: '/supercases/{supercaseId}/notes',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'notes'],
}

export const privateNoteCreate: OperationData<Types.PrivateNoteCreate> = {
  method: 'post',
  name: 'privateNoteCreate',
  summary: 'Create private note',
  path: '/supercases/{supercaseId}/notes',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'notes'],
}

export const privateNoteGet: OperationData<Types.PrivateNoteGet> = {
  method: 'get',
  name: 'privateNoteGet',
  summary: 'Get private note by ID',
  path: '/supercases/{supercaseId}/notes/{noteId}',
  queryKey: ({ supercaseId, noteId }) => ['supercases', supercaseId, 'notes', noteId],
}

export const privateNoteUpdate: OperationData<Types.PrivateNoteUpdate> = {
  method: 'put',
  name: 'privateNoteUpdate',
  summary: 'Update private note',
  path: '/supercases/{supercaseId}/notes/{noteId}',
  queryKey: ({ supercaseId, noteId }) => ['supercases', supercaseId, 'notes', noteId],
}

export const globalNoteList: OperationData<Types.GlobalNoteList> = {
  method: 'get',
  name: 'globalNoteList',
  summary: 'Get global notes',
  path: '/supercases/{supercaseId}/global-notes',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'global-notes'],
}

export const globalNoteCreate: OperationData<Types.GlobalNoteCreate> = {
  method: 'post',
  name: 'globalNoteCreate',
  summary: 'Create global note',
  path: '/supercases/{supercaseId}/global-notes',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'global-notes'],
}

export const globalNoteGet: OperationData<Types.GlobalNoteGet> = {
  method: 'get',
  name: 'globalNoteGet',
  summary: 'Get global note by ID',
  path: '/supercases/{supercaseId}/global-notes/{noteId}',
  queryKey: ({ supercaseId, noteId }) => ['supercases', supercaseId, 'global-notes', noteId],
}

export const globalNoteUpdate: OperationData<Types.GlobalNoteUpdate> = {
  method: 'put',
  name: 'globalNoteUpdate',
  summary: 'Update global note',
  path: '/supercases/{supercaseId}/global-notes/{noteId}',
  queryKey: ({ supercaseId, noteId }) => ['supercases', supercaseId, 'global-notes', noteId],
}

export const getSupercaseDocuments: OperationData<Types.GetSupercaseDocuments> = {
  method: 'get',
  name: 'getSupercaseDocuments',
  summary: 'Get the list of supercase documents',
  path: '/supercases/{supercaseId}/documents',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'documents'],
}

export const setSupercaseDocuments: OperationData<Types.SetSupercaseDocuments> = {
  method: 'put',
  name: 'setSupercaseDocuments',
  summary: 'Set the documents associated with the supercase',
  path: '/supercases/{supercaseId}/documents',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'documents'],
}

export const addSupercaseDocuments: OperationData<Types.AddSupercaseDocuments> = {
  method: 'post',
  name: 'addSupercaseDocuments',
  summary: 'Associate documents with the supercase',
  path: '/supercases/{supercaseId}/documents',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'documents'],
}

export const supercaseBulkUpdate: OperationData<Types.SupercaseBulkUpdate> = {
  method: 'post',
  name: 'supercaseBulkUpdate',
  summary: 'Update all subcases',
  path: '/supercases/{supercaseId}/bulk-update',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'bulk-update'],
}

export const supercaseBulkSendMessage: OperationData<Types.SupercaseBulkSendMessage> = {
  method: 'post',
  name: 'supercaseBulkSendMessage',
  summary: 'Send a message to the borrowers on all subcases',
  path: '/supercases/{supercaseId}/bulk-send',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'bulk-send'],
}

export const populationBulkSendMessage: OperationData<Types.PopulationBulkSendMessage> = {
  method: 'post',
  name: 'populationBulkSendMessage',
  summary: 'Send a message to the borrowers on all subcases of the population',
  path: '/supercases/{supercaseId}/populations/{populationId}/bulk-send',
  queryKey: ({ supercaseId, populationId }) => ['supercases', supercaseId, 'populations', populationId, 'bulk-send'],
}

export const supercaseBulkCreateDni: OperationData<Types.SupercaseBulkCreateDni> = {
  method: 'post',
  name: 'supercaseBulkCreateDni',
  summary: 'Create Do Not Interact instances on all subcases',
  path: '/supercases/{supercaseId}/bulk-create-do-not-interact',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'bulk-create-do-not-interact'],
}

export const supercaseBulkDeleteDni: OperationData<Types.SupercaseBulkDeleteDni> = {
  method: 'post',
  name: 'supercaseBulkDeleteDni',
  summary: 'Delete supercase related Do Not Interact instances on all subcases',
  path: '/supercases/{supercaseId}/bulk-delete-do-not-interact',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'bulk-delete-do-not-interact'],
}

export const supercaseBulkOperationList: OperationData<Types.SupercaseBulkOperationList> = {
  method: 'get',
  name: 'supercaseBulkOperationList',
  summary: 'List bulk operations on this supercase',
  path: '/supercases/{supercaseId}/bulk-operations',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'bulk-operations'],
}

export const supercaseBulkOperationSearch: OperationData<Types.SupercaseBulkOperationSearch> = {
  method: 'get',
  name: 'supercaseBulkOperationSearch',
  summary: 'Search bulk operations on this supercase',
  path: '/supercases/{supercaseId}/bulk-operations/search',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'bulk-operations', 'search'],
}

export const supercaseBulkOperationGet: OperationData<Types.SupercaseBulkOperationGet> = {
  method: 'get',
  name: 'supercaseBulkOperationGet',
  summary: 'Get supercase bulk operation by ID',
  path: '/supercases/{supercaseId}/bulk-operations/{supercaseBulkOperationId}',
  queryKey: ({ supercaseId, supercaseBulkOperationId }) => [
    'supercases',
    supercaseId,
    'bulk-operations',
    supercaseBulkOperationId,
  ],
}

export const supercaseBulkOperationUpdate: OperationData<Types.SupercaseBulkOperationUpdate> = {
  method: 'put',
  name: 'supercaseBulkOperationUpdate',
  summary: 'Update supercase bulk operation',
  path: '/supercases/{supercaseId}/bulk-operations/{supercaseBulkOperationId}',
  queryKey: ({ supercaseId, supercaseBulkOperationId }) => [
    'supercases',
    supercaseId,
    'bulk-operations',
    supercaseBulkOperationId,
  ],
}

export const getBulkOperationTargetPopulations: OperationData<Types.GetBulkOperationTargetPopulations> = {
  method: 'get',
  name: 'getBulkOperationTargetPopulations',
  summary: 'Get target populations for the supercase bulk operation',
  path: '/supercases/{supercaseId}/bulk-operations/{supercaseBulkOperationId}/target-populations',
  queryKey: ({ supercaseId, supercaseBulkOperationId }) => [
    'supercases',
    supercaseId,
    'bulk-operations',
    supercaseBulkOperationId,
    'target-populations',
  ],
}

export const getMemberBulkOperationResultsByOperation: OperationData<Types.GetMemberBulkOperationResultsByOperation> = {
  method: 'get',
  name: 'getMemberBulkOperationResultsByOperation',
  summary: 'Get member bulk operation results by operation',
  path: '/supercases/{supercaseId}/bulk-operations/{supercaseBulkOperationId}/member-bulk-operation-results',
  queryKey: ({ supercaseId, supercaseBulkOperationId }) => [
    'supercases',
    supercaseId,
    'bulk-operations',
    supercaseBulkOperationId,
    'member-bulk-operation-results',
  ],
}

export const updateMemberBulkOperationResult: OperationData<Types.UpdateMemberBulkOperationResult> = {
  method: 'put',
  name: 'updateMemberBulkOperationResult',
  summary: 'Update member bulk operation result',
  path: '/supercases/{supercaseId}/bulk-operations/{supercaseBulkOperationId}/member-bulk-operation-results/{populationMembershipId}',
  queryKey: ({ supercaseId, supercaseBulkOperationId, populationMembershipId }) => [
    'supercases',
    supercaseId,
    'bulk-operations',
    supercaseBulkOperationId,
    'member-bulk-operation-results',
    populationMembershipId,
  ],
}

export const internalSupercaseBulkOperationGet: OperationData<Types.InternalSupercaseBulkOperationGet> = {
  method: 'get',
  name: 'internalSupercaseBulkOperationGet',
  summary: 'Get supercase bulk operation by ID',
  path: '/companies/{companyId}/bulk-operations/{supercaseBulkOperationId}',
  queryKey: ({ companyId, supercaseBulkOperationId }) => [
    'companies',
    companyId,
    'bulk-operations',
    supercaseBulkOperationId,
  ],
}

export const internalSupercaseBulkOperationList: OperationData<Types.InternalSupercaseBulkOperationList> = {
  method: 'get',
  name: 'internalSupercaseBulkOperationList',
  summary: 'List bulk operations for this company',
  path: '/companies/{companyId}/bulk-operations',
  queryKey: ({ companyId }) => ['companies', companyId, 'bulk-operations'],
}

export const supercaseCaseCount: OperationData<Types.SupercaseCaseCount> = {
  method: 'get',
  name: 'supercaseCaseCount',
  summary: 'Get the number of cases associated with the supercase',
  path: '/supercases/{supercaseId}/case-count',
  queryKey: ({ supercaseId }) => ['supercases', supercaseId, 'case-count'],
}
