import { ReactNode, JSX, FC } from 'react'

import {
  DropdownIndicatorProps,
  GroupBase,
  MenuListProps,
  OptionProps,
  Props,
  ValueContainerProps,
  components,
} from 'react-select'
import { styled } from 'styled-components'

import Icon, { IconProps } from 'core/components/Icon/Icon'
import { IconName } from 'core/components/Icon/iconDefinitions'
import Chip, { ChipProps } from 'core/components/lib/Chip/Chip'
import Spinner from 'core/components/lib/Spinner'
import { R } from 'core/helpers'
import { variables } from 'core/styles'

export type SelectOption<T = string> = {
  label: string | JSX.Element
  sublabel?: string | JSX.Element
  value: T
  icon?: IconProps['name']
  tag?: string
  tagVariant?: ChipProps['$variant']
  isDisabled?: boolean
}

export type OptionsOrGroups<T = string> = ReadonlyArray<SelectOption<T> | GroupBase<SelectOption<T>>>

export const findByValue = <T,>(options: OptionsOrGroups<T> | undefined, value: T) =>
  R.pipe(
    options ?? [],
    R.flatMap((o) => ('options' in o ? o.options : o)),
    R.find((o) => R.equals(o.value, value)),
  )

export const DropdownIndicator = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>,
) => (
  <components.DropdownIndicator {...props}>
    <Icon color={variables.colorBlack90} fontSize='24px' name='arrow_drop_down' />
  </components.DropdownIndicator>
)

// Workaround JedWatson/react-select#5625
export const MenuList = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
  innerProps,
  children,
  ...props
}: MenuListProps<Option, IsMulti, Group> & {
  selectProps: Props<Option, IsMulti, Group> & { hasNextPage?: boolean }
}) => (
  <components.MenuList {...props} innerProps={{ ...innerProps, role: 'listbox' }}>
    {children}
    {props.selectProps.isLoading ?
      <Spinner height='40px' />
    : props.selectProps.hasNextPage ?
      <Spacer />
    : null}
  </components.MenuList>
)

const Spacer = styled.div`
  height: 40px;
`

export const Option = <Value, IsMulti extends boolean, Group extends GroupBase<SelectOption<Value>>>({
  innerProps,
  ...props
}: OptionProps<SelectOption<Value>, IsMulti, Group>) => {
  const { icon, label, sublabel, tag, tagVariant } = props.data

  return (
    <components.Option {...props} innerProps={{ ...innerProps, role: 'option', 'aria-selected': props.isSelected }}>
      {icon && <Icon fontSize='24px' margin='0 8px 0 0' name={icon} />}
      <Grow>
        <Label>
          {label}
          {tag ?
            <Chip $variant={tagVariant ?? 'dim'}>{tag}</Chip>
          : null}
        </Label>
        {sublabel && <Sublabel>{sublabel}</Sublabel>}
      </Grow>
    </components.Option>
  )
}

export const ValueContainer = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
  children,
  ...props
}: ValueContainerProps<Option, IsMulti, Group> & {
  selectProps: Props<Option, IsMulti, Group> & { prefix?: ReactNode }
}) => (
  <>
    {!!props.selectProps.prefix && <Prefix>{props.selectProps.prefix}</Prefix>}
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  </>
)

export type PlaceholderProps = {
  label: string
  icon?: IconName
}

export const Placeholder: FC<PlaceholderProps> = ({ label, icon }) => (
  <PlaceholderBox>
    {icon && <Icon fontSize='24px' name={icon} />}
    {label}
  </PlaceholderBox>
)

const PlaceholderBox = styled.div`
  display: flex;
  gap: 8px;
  color: ${variables.colorBlack70};
`

const Grow = styled.div`
  flex-grow: 1;
`

const Label = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`

const Sublabel = styled.div`
  text-transform: capitalize;
  color: ${variables.colorBlack60};
  font-size: 14px;
  font-weight: 300;
`

const Prefix = styled.div`
  display: flex;
  align-items: center;
  padding: 0 4px 0 12px;
  color: ${variables.colorBlack70};
`
