import { FC, PropsWithChildren } from 'react'

import * as ToastPrimitive from '@radix-ui/react-toast'
import { styled } from 'styled-components'

import { R } from 'core/helpers'

import useToastStore, { removeToast } from './store'
import Toast from './Toast'

const ToastViewport: FC<PropsWithChildren<unknown>> = () => {
  const toasts = useToastStore()

  return (
    <ToastPrimitive.Provider duration={7000}>
      {R.map(toasts, (toast) => (
        <Toast key={toast.id} {...toast} onOpenChange={(isOpen) => !isOpen && removeToast(toast.id)} />
      ))}

      <ToastPrimitive.Viewport asChild>
        <StyledToaster />
      </ToastPrimitive.Viewport>
    </ToastPrimitive.Provider>
  )
}

export default ToastViewport

const StyledToaster = styled.ol`
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: 20;
  margin: 0;
  padding: 0;
  list-style: none;
`
