import * as R from 'core/helpers/remeda'
import * as S from 'core/helpers/string'
import { DoNotInteractChannel, DoNotInteractTheme } from 'core/types/types'

import { interactionThemeLabels } from './interactions'

export const dniThemes = [
  'opsServicing',
  'opsServicingDebtValidation',
  'opsServicingTimeBarredNotice',
  'opsCollDebt',
  'opsCollContactEmployerNotice',
  'opsCollLocateBorrower',
  'opsCollVerifyEmployment',
] as const satisfies Array<DoNotInteractTheme>

export const dniThemeOptions = R.map(dniThemes, (theme) => ({ label: interactionThemeLabels[theme], value: theme }))

export const dniChannels = ['voice', 'email', 'text', 'chat', 'mail'] as const satisfies Array<
  Exclude<DoNotInteractChannel, 'fax'>
>

export const dniChannelOptions = R.map(dniChannels, (value) => ({ value, label: S.capitalize(value) }))
