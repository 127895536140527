import { FC } from 'react'

import { styled } from 'styled-components'

import { getGenericTimezone } from 'core/helpers'
import { useNow } from 'core/hooks'
import { useTime } from 'core/time'

type CurrentTimestampProps = {
  align?: 'left' | 'center' | 'right'
  margin?: string
}

const CurrentTimestamp: FC<CurrentTimestampProps> = ({ align, margin }) => {
  const { format } = useTime()

  const now = useNow(60 * 1000)

  return (
    <StyledTimeStamp align={align} margin={margin}>
      {format(now, 'MMM D, h:mm A')} {getGenericTimezone()}
    </StyledTimeStamp>
  )
}

export default CurrentTimestamp

export const StyledTimeStamp = styled.span<{ margin?: string; align?: string }>`
  display: block;
  margin: ${(p) => (p.margin ? p.margin : undefined)};
  text-align: ${(p) => (p.align ? p.align : undefined)};
  line-height: 24px;
  color: var(--colorBlack50);
  font-size: 14px;
  font-weight: 400;
`
