import { FC } from 'react'

import { useUserType } from 'core/stores'

import ActionDisabledMessage from './ActionDisabledMessage'

type PermissionMessageProps = {
  backUrl: string
}

const PermissionMessage: FC<PermissionMessageProps> = ({ backUrl }) => {
  const userType = useUserType()

  return (
    <ActionDisabledMessage
      backUrl={backUrl || '/loans'}
      message={
        userType === 'agent' ?
          'You do not have the required permissions to perform this action. Please contact your supervisor for help.'
        : 'You do not have the required permissions to perform this action. Please contact support for help.'
      }
    />
  )
}

export default PermissionMessage
