import { styled } from 'styled-components'

import { variables } from 'core/styles'

type BannerProps = {
  /** The visual style of the banner. */
  $variant: 'error' | 'success' | 'warning' | 'ghost'
}

/**
 * Display a short message frequently at the bottom of a component. Will match the parent's border radius.
 */
const Banner = styled.div<BannerProps>`
  display: flex;
  grid-column: 1 / -1;
  align-items: center;
  background-color: ${(p) =>
    p.$variant === 'ghost' ? 'transparent'
    : p.$variant === 'error' ? variables.colorRedLighten
    : p.$variant === 'warning' ? variables.colorOrangeLighten
    : variables.colorGreenLighten};
  padding: 4px 16px;
  color: ${variables.colorBlack70};
  font-size: 14px;

  &:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
`

export default Banner
