import { parse, format, getDay, nextDay, Day } from 'date-fns'

import { R, SIMPLE_DATE_FORMAT, TIME_FORMAT, getNow } from 'core/helpers'
import { SingleDayHours, SingleHoliday } from 'core/types/lenderConfig'

const dayMap = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
}

const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

const formatHours = (hours: string) => format(parse(hours, 'HH:mm', getNow()), TIME_FORMAT)

const formatDayOfWeekHours = (singleDay: SingleDayHours, holidaysByDate: { [index: string]: SingleHoliday }) => {
  const { closed, day, hoursList } = singleDay

  const now = getNow()
  const dayIndex = daysOfWeek.indexOf(day.toLowerCase()) as Day
  const thisDate = format(getDay(now) === dayIndex ? now : nextDay(now, dayIndex), SIMPLE_DATE_FORMAT)

  return {
    dayString: dayMap[day.toLowerCase()],
    hoursString:
      holidaysByDate[thisDate] ?
        'Closed' + (holidaysByDate[thisDate].name ? ` due to ${holidaysByDate[thisDate].name}` : '')
      : singleDay['24hours'] ? 'Open 24 hours'
      : closed ? 'Closed'
      : R.pipe(
          hoursList ?? [],
          R.map(([from, to]) => `${formatHours(from)}-${formatHours(to)}`),
          R.join(', '),
        ),
  }
}

export default formatDayOfWeekHours
