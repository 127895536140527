import { FC } from 'react'

import { styled } from 'styled-components'

import { Clickable, PoweredByPeach } from 'core/components'
import { R, formatAddress } from 'core/helpers'
import { useAccountOptions, useBorrowerBaseUrl } from 'core/hooks'
import { useCompanyInfo, useUserType } from 'core/stores'
import { loanAppLayout } from 'core/styles'
import variables from 'core/styles/variables'

const Footer: FC = () => {
  const { showPoweredByPeach, legalName, brandName, name, support, brandAssets, ui } = useCompanyInfo()

  const baseUrl = useBorrowerBaseUrl()

  const options = useAccountOptions({ isMobile: false })

  const userType = useUserType()
  const isBorrower = userType === 'borrower'

  const displayName = legalName || brandName || name

  return (
    <div>
      {ui?.mobileFooter !== 'hidden' && (
        <Top>
          <TopContent>
            <Column>
              {R.pipe(
                options ?? [],
                R.reject((option) => !!option.disabled),
                R.map((option) => (
                  <Clickable
                    href={option?.href}
                    key={option?.label}
                    to={option?.action ? baseUrl + option.action : undefined}
                    variant='link'
                  >
                    {option?.label}
                  </Clickable>
                )),
              )}
            </Column>
            <Address>
              {displayName && <span>{displayName}</span>}

              {support?.mailingAddress && <span>{formatAddress(support?.mailingAddress, { twoLines: true })}</span>}
            </Address>
            {brandAssets?.copies?.consent && (
              <Consent dangerouslySetInnerHTML={{ __html: brandAssets?.copies?.consent }} />
            )}
          </TopContent>
        </Top>
      )}
      <Bottom>
        {ui?.mobileDisclosures !== 'hidden' && brandAssets?.copies?.disclosures && (
          <Disclosures dangerouslySetInnerHTML={{ __html: brandAssets?.copies?.disclosures }} />
        )}

        {showPoweredByPeach && !isBorrower && <PoweredByPeach />}
      </Bottom>
    </div>
  )
}

export default Footer

const Top = styled.div`
  background-color: ${variables.colorBlack20};
`

const TopContent = styled.div`
  box-sizing: border-box;
  display: flex;
  gap: 48px;
  margin: 0 auto;
  padding: 24px;
  max-width: 1024px;

  & > div {
    flex-basis: 10px;
    flex-grow: 1;
  }

  @media (max-width: ${loanAppLayout.maxWidthMobile}px) {
    flex-direction: column;
  }
`

const Column = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
`

const Address = styled(Column)`
  white-space: pre-wrap;
  color: ${variables.colorBlack70};
`

const Consent = styled.div`
  white-space: pre-wrap;
  color: ${variables.colorBlack70};

  a {
    text-decoration: none;
    color: ${variables.colorBluePrimary};

    &:hover {
      text-decoration: underline;
    }
  }
`

const Bottom = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  margin: 40px auto 24px;
  padding: 0 16px;
  max-width: 1024px;
`

const Disclosures = styled(Consent)`
  line-height: 24px;
  font-size: 12px;
`
