import { TZDate } from '@date-fns/tz'
import { format, parse } from 'date-fns'

import { bootstrapStore } from 'core/stores'

import * as R from './remeda'

export const getNow = (timezone: string | undefined = bootstrapStore.state?.company?.timezone) =>
  timezone ? TZDate.tz(timezone) : new Date()

const simpleDatePattern = /^\d{4}-\d{2}-\d{2}$/

export const parseDatetime = (
  value: string | number,
  timezone: string | undefined = bootstrapStore.state?.company?.timezone,
) =>
  // Date only forms are interpreted as UTC so we use `parse` instead of the date constructor
  R.isString(value) && simpleDatePattern.test(value) ? parse(value, SIMPLE_DATE_FORMAT, getNow(timezone))
  : !timezone ? new Date(value)
  : R.isString(value) ? new TZDate(value, timezone)
  : new TZDate(value, timezone)

export const getGenericTimezone = (
  timeZone: string | undefined = bootstrapStore.state?.company?.timezone,
  timeZoneName: 'longGeneric' | 'short' | 'long' | 'shortOffset' | 'longOffset' | 'shortGeneric' = 'longGeneric',
) =>
  R.find(
    new Intl.DateTimeFormat('default', { timeZone, timeZoneName }).formatToParts(),
    (part) => part.type === 'timeZoneName',
  )?.value ?? timeZone

// For future use
// export const DATETIME_FORMAT = 'd MMM y, h:mm a'
export const DATE_FORMAT = 'MMM d, yyyy'
export const TIME_FORMAT = 'h:mm a'
export const CALENDAR_DATE_FORMAT = 'MM/dd/yyyy'
export const SIMPLE_DATE_FORMAT = 'yyyy-MM-dd'

export const formatDatetime = (value: string, formatString: string, timezone?: string) =>
  format(parseDatetime(value, timezone), formatString)

export const formatToday = (timezone?: string) => format(getNow(timezone), SIMPLE_DATE_FORMAT)
