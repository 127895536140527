import { ForwardedRef, ReactNode, forwardRef } from 'react'

import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

import Icon from 'core/components/Icon'
import Spinner from 'core/components/lib/Spinner/Spinner'
import { R } from 'core/helpers'
import { PendingEventHandler, usePendingCallback } from 'core/hooks'
import {
  error,
  onError,
  onPrimary,
  onSuccess,
  onSurface,
  onSurfaceDisabled,
  outline,
  primary,
  success,
  surface,
  surfaceBright,
  surfaceBrightHover,
  surfaceBrightPressed,
  surfaceDisabled,
  variables,
} from 'core/styles'

type ClickableProps = {
  className?: string
  /** The text and / or icon to display. */
  children?: ReactNode
  /** The visual style of the clickable. */
  variant: 'link' | 'primary' | 'secondary' | 'success' | 'error' | 'outline' | 'ghost'
  /** The visual size of the clickable. Controls both font and physical size. */
  size?: 'xs' | 'sm' | 'md' | 'lg'
  /** Correlates to the HTML button `type` attribute. */
  type?: 'button' | 'reset' | 'submit'
  /** Shows a spinner after `children` while true. */
  isLoading?: boolean
  /** Disables all interactivity while true. */
  disabled?: boolean
  /** Navigates to the provided route when clicked. */
  to?: string
  /** Opens the provided url when clicked. Automatically shows an external link icon when set. */
  href?: string
  /** Calls the provided function when clicked. Return a promise to show a spinner while the promise resolves.  */
  onClick?: PendingEventHandler
}

/**
 * A component for buttons and links. Only one of `to`, `href`, or `onClick` can be provided.
 */
const Clickable = forwardRef<HTMLElement, ClickableProps>(
  (
    {
      variant,
      size = 'md',
      type = 'button',
      disabled = false,
      isLoading = false,
      onClick: _onClick,
      to,
      href,
      children,
      ...rest
    },
    ref,
  ) => {
    const [isPending, onClick] = usePendingCallback(_onClick)

    if (R.pipe([_onClick, to, href], R.filter(Boolean), R.length()) > 1) {
      console.warn('Clickable: can only provide one of `onClick`, `to`, or `href`')
    }

    const props = {
      $disabled: disabled || isPending,
      $size: size,
      $variant: variant,
      ...rest,
    }

    return (
      !R.isNil(to) ?
        <ClickableBase as={Link} ref={ref as ForwardedRef<HTMLAnchorElement>} to={to} {...props}>
          {children}
          {(isPending || isLoading) && <StyledSpinner width='24px' />}
        </ClickableBase>
      : !R.isNil(href) ?
        <ClickableBase
          as='a'
          href={href}
          ref={ref as ForwardedRef<HTMLAnchorElement>}
          rel='noopener noreferrer'
          target='_blank'
          {...props}
          $external
        >
          <span>{children}</span>
          <Icon margin='0 0 0 8px' name='open_in_new' />
          {(isPending || isLoading) && <StyledSpinner width='24px' />}
        </ClickableBase>
      : <ClickableBase
          disabled={disabled || isPending}
          onClick={onClick}
          ref={ref as ForwardedRef<HTMLButtonElement>}
          type={type}
          {...props}
        >
          {children}
          {(isPending || isLoading) && <StyledSpinner width='24px' />}
        </ClickableBase>
    )
  },
)

Clickable.displayName = 'Clickable'

export default Clickable

export const ClickableBase = styled.button<{
  $external?: boolean
  $disabled: boolean
  $size: 'xs' | 'sm' | 'md' | 'lg'
  $variant: 'link' | 'primary' | 'secondary' | 'success' | 'error' | 'outline' | 'ghost'
}>`
  display: flex;
  align-items: center;
  margin: 0;
  border: none;
  cursor: pointer;
  width: auto;
  font-weight: 500;

  &:hover {
    filter: brightness(0.92);
  }

  &:active {
    filter: brightness(0.85);
  }

  ${(p) =>
    p.$size === 'xs' ?
      `
      border-radius: 6px;
      padding: 3px 8px;
      font-size: 12px;
      `
    : p.$size === 'sm' ?
      `
      border-radius: 6px;
      padding: 5px 10px;
      font-size: 12px;
      `
    : p.$size === 'lg' ?
      `
      border-radius: 8px;
      padding: 9px 14px;
      font-size: 16px;
      `
    : `
      border-radius: 8px;
      padding: 7px 12px;
      font-size: 14px;
      `}

  ${(p) =>
    p.$variant === 'link' ?
      `
      display: inline-flex;
      background-color: transparent;
      padding: 0;
      color: ${variables.colorBluePrimary};

      ${
        p.$external ?
          `
          &:hover > span:first-child  {
            text-decoration: underline;
          }
          `
        : `
          &:hover  {
            text-decoration: underline;
          }
          `
      }
      `
    : p.$variant === 'primary' ?
      `
      background-color: ${primary};
      color: ${onPrimary};
      `
    : p.$variant === 'secondary' ?
      `
      background-color: transparent;
      color: ${variables.colorBluePrimary};

      &:hover {
        background-color: ${surface};
        filter: none;
      }
      `
    : p.$variant === 'success' ?
      `
      background-color: ${success};
      color: ${onSuccess};
      `
    : p.$variant === 'error' ?
      `
      background-color: ${error};
      color: ${onError};
      `
    : p.$variant === 'outline' ?
      `
      background-color: ${surfaceBright};
      color: ${onSurface};
      border: 1px solid ${outline};

      &:hover {
        background-color: ${surfaceBrightHover};
      }

      &:active {
        background-color: ${surfaceBrightPressed};
      }

      `
    : p.$variant === 'ghost' ?
      `
      background-color: transparent;
      color: ${onSurface};

      &:hover {
        background-color: ${surface};
      }

      &:active {
        background-color: transparent;
      }
      `
    : ''}

  ${(p) =>
    p.$disabled &&
    `
    ${p.$variant !== 'link' && `background-color: ${surfaceDisabled};`}
    cursor: default;
    outline: none;
    color: ${onSurfaceDisabled};
    pointer-events: none;

    &:hover {
      filter: none;
    }

    &:active {
      filter: none;
    }
  `}
`

const StyledSpinner = styled(Spinner)`
  margin-left: 8px;
`
