import qs from 'qs'

import { assert } from 'core/helpers/assert'
import * as R from 'core/helpers/remeda'
import type { PeachError } from 'core/types/utils'

/**
 * Returns an error object that can be thrown, after doing its best to glean
 * information from the response text to add to the error object.
 *
 * @param response - The response object. Make sure its stream hasn't already
 * been read yet by .text() or .json().
 */
export const parseErrorResponse = async (response: Response) => {
  // start with at least default error
  const error: PeachError = {
    status: response?.status ?? 0,
    message: 'Unknown Error',
    peachRequestId: response.headers.get('X-Peach-Request-Id') || undefined,
  }

  try {
    const { status, message, ...rest } = await response.json()

    if (status) {
      error.status = status
    }

    if (message) {
      error.message = message
    }

    Object.assign(error, rest)
  } catch {
    // Ignore error
  }

  return error
}

export type FetchOptions = Omit<RequestInit, 'body'> & {
  url: string
  query?: { [index: string]: any }
  body?: unknown
  withHeaders?: boolean
}

/**
 * This is wrapper around `window.fetch` (but that could be subbed out for
 * something else later) that
 *
 * - returns a promise
 * - resolves to a value
 * - rejects with an error with at least `status` and `message` fields
 * - Parses a `query` option if passed in
 * - Adds default headers
 * - JSON.stringifies body if an object is passed
 * - Gracefully handle 204
 * - Parses JSON, text, and pdf responses
 * - Parses JSON error
 *
 * Note: does not handle authorization or unauthorized handling. That's one level up in peachApi.js
 */
const rawApiCall = async ({ url, headers, body, query, method, withHeaders, ...restOptions }: FetchOptions) => {
  assert(R.isString(url), 'url is required for an API call')

  const fetchOptions: RequestInit = {
    method,
    headers: {
      Accept: 'application/json',
      ...headers,
    },
    ...restOptions,
  }

  if (!(body instanceof FormData)) {
    fetchOptions.headers!['Content-Type'] = 'application/json'
  }

  if (body instanceof FormData) {
    fetchOptions.body = body
  } else if (!R.isNil(body)) {
    fetchOptions.body = R.isString(body) ? body : JSON.stringify(body)
  }

  const resp = await window.fetch(
    url + qs.stringify(query, { addQueryPrefix: true, arrayFormat: 'comma' }),
    fetchOptions,
  )

  if (resp.ok) {
    const contentType = resp.headers.get('Content-Type')
    return (
      resp.status === 204 && withHeaders ? R.pick(resp, ['headers', 'status'])
      : resp.status === 204 ? {}
      : contentType?.includes('json') ? resp.json()
      : contentType?.includes('text') ? resp.text()
      : contentType?.includes('pdf') ? resp.blob()
      : {}
    )
  } else {
    throw await parseErrorResponse(resp)
  }
}

export default rawApiCall
