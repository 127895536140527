import { FC, ReactChild } from 'react'

import { Item, Indicator } from '@radix-ui/react-radio-group'
import { styled } from 'styled-components'

import variables from 'core/styles/variables'

type RadioItemProps = {
  className?: string
  /** The unique string to pass to `RadioGroup`s `onChange` prop. */
  value: string
  /** Disables all interactivity while true. */
  disabled?: boolean
  /** The text to display alongside the checkbox. */
  label?: ReactChild
  /** The text for accessibility for when `label` is not used. */
  ariaLabel?: string
}

/** A single radio input. Use with `RadioGroup`. */
const RadioItem: FC<RadioItemProps> = ({ value, disabled, label, ariaLabel, className }) => (
  <Box $disabled={disabled} className={className}>
    <StyledItem aria-label={ariaLabel} disabled={disabled} id={value} value={value}>
      <StyledIndicator forceMount />
    </StyledItem>

    {!!label && <label htmlFor={value}>{label}</label>}
  </Box>
)

export default RadioItem

const Box = styled.div<{ $disabled: boolean | undefined }>`
  display: flex;
  gap: 8px;
  align-items: center;

  button,
  label {
    cursor: ${(p) => (p.$disabled ? 'default' : 'pointer')};
  }

  [data-disabled] & button,
  [data-disabled] & label {
    cursor: default;
  }
`

const StyledItem = styled(Item)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${variables.colorBlack70};
  border-radius: 100%;
  background-color: ${variables.colorWhite};
  width: 20px;
  height: 20px;

  &[data-state='checked'] {
    border-color: ${(p) => p.theme.primary};
    background-color: ${(p) => p.theme.secondary};
  }

  &[data-disabled] {
    border-color: ${variables.colorBlack20};
    background-color: ${variables.colorBlack20};
  }
`

const StyledIndicator = styled(Indicator)`
  transform: scale(0);
  opacity: 0;
  border-radius: 100%;
  background-color: ${variables.colorWhite};
  width: 6px;
  height: 6px;

  &[data-state='checked'] {
    transform: scale(1);
    transition: all 0.2s ease-in;
    opacity: 1;
  }
`
