// Error import: keep at top
import './polyfill'
import 'core/system/ErrorLogging'
import 'index.scss'
import { createRoot } from 'react-dom/client'

import 'core/components/lib/Skeleton/makeSkeleton'
import { getOverride } from 'core/components/PeachOverrides/storage'
import Root from 'core/SystemProvider/Root'

if (import.meta.env.VITE_ENABLE_API_MOCKING && getOverride('enableApiMocking')) {
  void import('core/components/PeachOverrides/msw').then((lib) => lib.start())
}

createRoot(document.getElementById('root')!).render(<Root />)
