import { ReactNode } from 'react'

import { nanoid } from '@reduxjs/toolkit'
import { useStore } from '@tanstack/react-store'
import { Store } from '@tanstack/store'

import * as R from 'core/helpers/remeda'

import type { ToastData } from './Toast'

export const toastStore = new Store<Array<ToastData>>([])

const useToastStore = () => useStore(toastStore)

export default useToastStore

export const addToast = (message: ReactNode, type: ToastData['type']) =>
  toastStore.setState((toasts) => [...toasts, { id: nanoid(), message, type }])

export const removeToast = (id: string) => toastStore.setState(R.reject((t) => t.id === id))
