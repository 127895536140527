import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form'

import { NotUndefined } from 'core/types'

import { MultiSelect, MultiSelectProps, Select, SelectProps } from './Select'

type SelectInputProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>, Value> = Omit<
  UseControllerProps<TFieldValues, TName>,
  'disabled'
> &
  Omit<SelectProps<Value>, 'disabled' | 'value' | 'name'> & {
    isDisabled?: boolean
  }

/** @deprecated Avoid react-form-hooks and use Select instead */
export const SelectInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  Value extends NotUndefined = string,
>({
  control,
  defaultValue,
  name,
  rules,
  shouldUnregister,
  isDisabled,
  onChange,
  ...props
}: SelectInputProps<TFieldValues, TName, Value>) => {
  const {
    formState: { isSubmitting },
    field: { onChange: controlOnChange, disabled, ...field },
  } = useController({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
    disabled: isDisabled,
  })

  return (
    <Select
      onChange={(value) => {
        controlOnChange(value)
        return onChange?.(value)
      }}
      {...field}
      {...props}
      disabled={disabled || isSubmitting}
    />
  )
}

type MultiSelectInputProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>, Value> = Omit<
  UseControllerProps<TFieldValues, TName>,
  'disabled'
> &
  Omit<MultiSelectProps<Value>, 'disabled' | 'value' | 'name'> & {
    isDisabled?: boolean
  }

/** @deprecated Avoid react-form-hooks and use MultiSelect instead */
export const MultiSelectInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  Value extends NotUndefined = string,
>({
  control,
  defaultValue,
  name,
  rules,
  shouldUnregister,
  isDisabled,
  onChange,
  ...props
}: MultiSelectInputProps<TFieldValues, TName, Value>) => {
  const {
    formState: { isSubmitting },
    field: { onChange: controlOnChange, disabled, ...field },
  } = useController({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
    disabled: isDisabled,
  })

  return (
    <MultiSelect
      onChange={(value) => {
        controlOnChange(value)
        return onChange?.(value)
      }}
      {...field}
      {...props}
      disabled={disabled || isSubmitting}
    />
  )
}
