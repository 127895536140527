import { FC, PropsWithChildren } from 'react'

import ContentLoader from 'react-content-loader'
import { styled } from 'styled-components'

type SkeletonProps = PropsWithChildren<{
  width: string
  height: string
  margin?: string
}>

/**
 * An SVG gradient based loader. Any SVG shape can be used.
 *
 * ```jsx
 * <Skeleton height="116px" width="216px">
 *   <rect height="100" width="200" x="8" y="8" />
 * </Skeleton>
 * ```
 *
 * While these can be written by hand a helper function `makeSkeleton` is provided on the window object during
 * development. Simply pass in any element and it will generated a skeleton based on the size and position of the
 * existing text and element nodes. The generated skeleton won't be perfect but it's a great starting point.
 */
const Skeleton: FC<SkeletonProps> = ({ children, width, height, margin }) => (
  <StyledContentLoader
    $margin={margin}
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'
    height={height}
    speed={5}
    width={width}
  >
    {children}
  </StyledContentLoader>
)

export default Skeleton

const StyledContentLoader = styled(ContentLoader)<{ $margin?: string }>`
  display: block;
  ${(p) => (p.$margin ? `margin: ${p.$margin};` : '')}

  rect {
    rx: 3px;
    ry: 3px;
  }
`
