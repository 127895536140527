import { FC, PropsWithChildren } from 'react'

import { styled } from 'styled-components'

import { CompanyLogo } from 'core/components'
import Footer from 'core/components/Layout/Footer'
import QueryProvider from 'core/system/queries/QueryProvider'

const LoginContentsArea = styled.div`
  padding: 16px;
  padding-bottom: 256px;
`

const LogoContainer = styled.div`
  padding-bottom: 16px;
  text-align: center;
`

const LoginLayout: FC<PropsWithChildren<{}>> = ({ children }) => (
  <QueryProvider>
    <LoginContentsArea>
      <LogoContainer>
        <CompanyLogo type='appLargeCentered' />
      </LogoContainer>
      {children}
    </LoginContentsArea>
    <Footer />
  </QueryProvider>
)

export default LoginLayout
